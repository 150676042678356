import apolloClient from "@/services/apollo-client-company";
import {
    JOB_LIST,
    JOB_DELETE
} from "@/graphql/Job";

export const getJobsApi = (form) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await apolloClient.query({
                query: JOB_LIST,
                variables: form,
            })
            resolve(res.data.getJobList)
        } catch (e) {
            reject(e)
        }
    })
}

export const deleteJobApi = (info) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await apolloClient.mutate({
                mutation: JOB_DELETE,
                variables: {
                    jobId: info._id,
                    type: info.type
                },
            })
            resolve(res)
        } catch (er) {
            reject(er)
        }
    })
}