<template>
  <div class="paginate-container">
    <!-- <div class="nav-tab show-item">
      <span class="active" @click="pages(10)" v-if="total < 10"> {{ total }}</span>
      <span @click="pages(10)" v-if="total >= 10" :class="{'active': $route.query.p <= 19}"> 10</span>
      <span v-if="total >= 11" @click="pages(20)" :class="{'active': $route.query.p == 20}">20</span>
      <span v-if="total >= 21" @click="pages(50)" :class="{'active': $route.query.p == 50}">50</span>
      <span v-if="total >= 51" @click="pages(100)" :class="{'active': $route.query.p == 100}">100</span>
      <span v-if="total >= 101" @click="pages(150)" :class="{'active': $route.query.p == 150}">150</span>
      <span v-if="total >= 151" @click="pages(200)" :class="{'active': $route.query.p == 200}">200</span>
      <span v-if="total >= 201" @click="pages(500)" :class="{'active': $route.query.p == 500}">500</span>
      <span class="disabled"> Total {{ total }} </span>
    </div> -->
    <div class="paginate">
      <div class="page-navi">
        <span @click="prev()"> Previous </span>

        <span
          :class="{ active: currentP === numOfPages[0] }"
          @click="firstPage()"
          v-if="currentP > 3"
        >
          {{ numOfPages[0] }}</span
        >
        <span v-if="currentP > 3"><i class="fas fa-ellipsis-h"></i></span>

        <span
          v-for="n in displayedpage"
          :key="n"
          @click="setP(n)"
          :class="{ active: currentP === n }"
          >{{ n }}</span
        >

        <span v-if="currentP < numOfPages.length - 2"
          ><i class="fas fa-ellipsis-h"></i
        ></span>
        <span
          @click="lastPage(numOfPages.length)"
          v-if="currentP < numOfPages.length - 2"
        >
          {{ numOfPages[numOfPages.length - 1] }}</span
        >

        <span @click="next()"> Next </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["total"],
  data: () => ({
    currentP: 1,
    page: 10,
  }),
  methods: {
    firstPage() {
      this.$router
        .push({ query: { ...this.$route.query, page: 1 } })
        .catch(() => {});
    },
    setP(n) {
      this.currentP = n;
      this.$router
        .push({ query: { ...this.$route.query, page: n } })
        .catch(() => {});
    },
    pages(a) {
      this.page = a;
      this.$router
        .push({ query: { ...this.$route.query, p: a, page: "1" } })
        .catch(() => {});
    },
    prev() {
      if (1 < this.currentP) {
        this.$router
          .push({ query: { ...this.$route.query, page: this.currentP - 1 } })
          .catch(() => {});
        this.currentP--;
      }
    },
    next() {
      if (this.currentP < this.numOfPages.length) {
        this.$router
          .push({ query: { ...this.$route.query, page: this.currentP + 1 } })
          .catch(() => {});
        this.currentP++;
      }
    },
  },
  computed: {
    numOfPages() {
      let index = [];
      const num = Math.ceil(this.total / this.page);
      for (let i = 1; i <= num; i++) {
        index.push(i);
      }
      return index;
    },
    displayedpage() {
      //display page
      if (this.currentP === 1) {
        return this.numOfPages.slice(this.currentP - 1, this.currentP + 4);
      } else if (this.currentP === 2) {
        return this.numOfPages.slice(this.currentP - 2, this.currentP + 3);
      } else if (this.currentP === 3) {
        return this.numOfPages.slice(this.currentP - 3, this.currentP + 2);
      } else if (this.currentP === this.numOfPages.length) {
        return this.numOfPages.slice(this.currentP - 5, this.currentP + 1);
      } else if (this.currentP === this.numOfPages.length - 1) {
        return this.numOfPages.slice(this.currentP - 4, this.currentP + 2);
      } else if (this.currentP === this.numOfPages.length - 2) {
        return this.numOfPages.slice(this.currentP - 3, this.currentP + 2);
      } else if (this.currentP >= 4 && this.currentP <= 7) {
        return this.numOfPages.slice(this.currentP - 2, this.currentP + 1);
      } else if (
        this.currentP > 7 &&
        this.currentP <= this.numOfPages.length - 3
      ) {
        return this.numOfPages.slice(this.currentP - 2, this.currentP + 1);
      }
    },
  },
  watch: {
    "$route.query.page": {
      handler(val) {
        this.currentP = parseInt(val);
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss">
.paginate-container {
  // search
  .paginate {
    margin-left: auto;

    .page-navi {
      display: flex;

      span {
        cursor: pointer;
        padding: 5px 10px;
        font-size: 14px;
        border: 1px solid var(--border-color);

        &.active {
          border-color: var(--primary-color);
          background-color: var(--primary-color);
          color: #fff;
        }
      }
    }
  }
}
</style>
