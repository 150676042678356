<template>
  <section class="section">
    <div class="container">
      <div class="page-header">
        <h3>{{ $route.query.type }} Job</h3>
      </div>

      <div class="box scroll-y slide-up">
        <div class="box-header">
          <div class="field">
            <input
              type="text"
              v-model="searchJobs"
              class="input"
              placeholder="Search"
              required
              @keyup="search"
            />
          </div>
          <custom :total="totals" />
        </div>

        <table class="table is-fullwidth slide-down">
          <thead>
            <tr>
              <th>Job Title</th>
              <th class="is-sm">Status</th>
              <th class="is-sm">View</th>
              <th class="is-md">Pubish Date</th>
              <th class="is-md">Expired Date</th>
              <th class="is-xs text-right">Option</th>
            </tr>
          </thead>
          <tbody>
            <tr class="td-row" v-for="(j, index) in joblist" :key="index">
              <td data-label="Job Title">{{ j.title }}</td>
              <!-- Job Title -->
              <td data-label="Status">{{ $route.query.type }}</td>
              <!-- View -->
              <td data-label="View">{{ j.isClick }}</td>
              <!-- View -->
              <td data-label="Pubish Date">{{ j.openingDate }}</td>
              <!-- Pubish Date -->
              <td data-label="Unpubish Date">{{ j.expiredDate }}</td>
              <!-- Unpubish Date -->
              <td data-label="Option">
                <div class="icon-group">
                  <span
                    @click="
                      $router.push({
                        name: 'description',
                        query: { id: j._id, type: $route.query.type },
                      })
                    "
                  >
                    <i
                      v-if="$route.query.type === 'expired'"
                      class="fad fa-retweet"
                    ></i>
                    <i v-else class="fad fa-pen"></i
                  ></span>
                  <span @click="deleteJob(j._id)">
                    <i class="fad fa-trash"></i
                  ></span>
                </div>
              </td>
              <!-- Option -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</template>

<script>
import custom from "../Pagination/customPagination";
import { getJobsApi, deleteJobApi } from "@/apis/job-api";
export default {
  components: { custom },
  data: () => ({
    totals: "",
    joblist: [],
    token: "",
    searchJobs: "",
    myjobs: [
      { position: "International Post-Harvest Specialist", service: "B" },
      { position: "Operator Business Account Manager", service: "H" },
      { position: "Project Officer", service: "H" },
      { position: "Chinese Business Development Executive", service: "B" },
    ],
  }),
  methods: {
    async search() {
      this.$router
        .push({
          query: {
            type: this.$route.query.type,
            page: 1,
            perPage: 10,
            search: this.searchJobs,
          },
        })
        .catch(() => {});
    },

    async deleteJob(_id) {
      const info = { _id, type: this.$route.query.type };
      this.$confirm(
        "If you are sure, click OK!",
        "Do you really want to delete?"
      ).then(async () => {
        await deleteJobApi(info);
        this.$route.query.type || "online";
        this.getJobList();
      });
    },
    async getJobList() {
      if (!this.$store.getters.getCompanyToken) return;
      const form = {
        type: this.$route.query.type || "online",
        page: parseInt(this.$route.query.page) || 1,
        perPage: parseInt(this.$route.query.perPage) || 10,
        search: this.$route.query.search || "",
      };
      const jobObject = await getJobsApi(form);
      this.joblist = jobObject.JobList;
      this.totals = jobObject.jobCount;
    },
  },
  watch: {
    "$store.getters.getCompanyToken": {
      handler(val) {
        this.getJobList();
      },
    },

    "$route.query.type": {
      handler(val) {
        this.getJobList();
      },
      immediate: true,
    },

    "$route.query.page": {
      handler(val) {
        this.getJobList();
      },
      immediate: true,
    },
    "$route.query.perPage": {
      handler(val) {
        this.getJobList();
      },
      immediate: true,
    },
    "$route.query.search": {
      handler(val) {
        this.getJobList();
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.selected {
  background-color: var(--primary-color) !important;
  i {
    color: #fff !important;
  }
}
.box {
  margin-bottom: 10px;
  .box-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    .field {
      margin: 0 !important;
    }
  }
}
.input-box {
  padding: 10px;
  display: flex;
  .input-group {
    display: flex;
    width: 100%;
    align-items: flex-end;
    .field {
      width: 50%;
      flex-grow: 1;
      padding: 0 10px;
      margin: 0 !important;
      display: flex;
      align-items: center;
      label {
        margin: 0 10px 0 0;
        white-space: pre;
        font-size: var(--sm-font);
      }
      .control {
        width: 100%;
        input {
          font-family: var(--font);
          &.error {
            border-color: var(--alert-color);
            background-color: rgba(var(--alert-color), 0.1);
          }
        }
      }
    }
  }
  button {
    margin-left: 10px;
    background-color: var(--primary-color);
    color: #fff;
    border: none;
    &:focus {
      box-shadow: none;
      box-shadow: unset;
    }
  }
}

.manage-content {
  padding: 20px;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  .manage-content-box {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    max-height: 100%;
    min-height: 100%;
    .box.scroll-y {
      min-height: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
      padding-bottom: 20px;
      flex-grow: 1;
      .table-container {
        overflow-y: auto;
        border-top: 1px solid var(--border-color);
        border-bottom: 1px solid var(--border-color);
        .sticky {
          position: sticky;
          top: 0;
          z-index: 9;
        }
      }
    }
  }
}

// scroll-bar style start here
::-webkit-scrollbar {
  background-color: #363636;
  border-radius: 50px;
  width: 8px;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(var(--grey-color), 0.5);
  border-radius: 50px;
  cursor: pointer;
}
</style>
