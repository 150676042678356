<template>
  <div>
    <div class="sub-nav">
      <div class="container">
        <div class="sub-nav-list">
          <span class="nav-title">My Jobs</span>
          <hr class="vline" />
          <div class="sub-nav-group">
            <router-link
              :to="{
                query: {
                  type: 'online',
                  page: $route.query.page,
                  perPage: $route.query.perPage,
                  search: $route.query.search,
                },
              }"
              class="sub-nav-item"
              :class="{ active: selected === 'online' }"
              >Online Job</router-link
            >
            <router-link
              :to="{
                query: {
                  type: 'offline',
                  page: $route.query.page,
                  perPage: $route.query.perPage,
                  search: $route.query.search,
                },
              }"
              class="sub-nav-item"
              :class="{ active: selected === 'offline' }"
              >Offline Job</router-link
            >
            <router-link
              :to="{
                query: {
                  type: 'schedule',
                  page: $route.query.page,
                  perPage: $route.query.perPage,
                  search: $route.query.search,
                },
              }"
              class="sub-nav-item"
              :class="{ active: selected === 'schedule' }"
              >Schedule</router-link
            >
            <router-link
              :to="{
                query: {
                  type: 'draft',
                  page: $route.query.page,
                  perPage: $route.query.perPage,
                  search: $route.query.search
                },
              }"
              class="sub-nav-item"
              :class="{ active: selected === 'draft' }"
              >Draft Job</router-link
            >
            <router-link
              :to="{
                query: {
                  type: 'expired',
                  page: $route.query.page,
                  perPage: $route.query.perPage,
                  search: $route.query.search
                },
              }"
              class="sub-nav-item"
              :class="{ active: selected === 'expired' }"
              >Expired Job</router-link
            >
          </div>
        </div>
      </div>
    </div>
    <jobList/>
  </div>
</template>
<script>

import jobList from "@coms/myjob/job-list";
export default {
  components: {
    jobList,
  },
  data: () => ({
    selected: "",
    isShowModal: false,
    selectedComponent: "jobList",
  }),
  methods: {
    closeModal() {
      this.isShowModal = false
    }
  },
  created() {
    if (this.$route.query.token) {
       this.$store.commit("setToken", this.$route.query.token);
       this.$router.push({ path: '/', query: { type: "online", page: 1, perPage: 10, search: "" } })
    }
  }
};
</script>

<style lang="scss" scoped>
</style>